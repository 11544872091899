import * as React from 'react'
import {ReactElement} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {SegmentedControlItem} from './SegmentedControlItem'

interface SegmentedControlProps {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Name of radio buttons group. Standard form field attribute */
  name: string
  /** An onChange callback passed down by the controlling parent component */
  onChange: (value: string) => void
  /** The value passed down by the controlling parent component */
  value: string
  /** Segmented Control items passed as children */
  children: ReactElement[]
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether the SegmentedControl should span the whole available width */
  isFullWidth?: boolean
}

const Wrapper = styled('div')<{isFullWidth: boolean}>(
  ({isFullWidth}) => css`
    ${isFullWidth && 'flex-grow: 1;'}
    display: flex;
    position: relative;
  `
)

const SegmentedControl = ({
  value,
  onChange,
  name,
  children,
  dataId,
  isFullWidth = false,
  className
}: SegmentedControlProps) => {
  return (
    <Wrapper data-id={dataId} className={className} isFullWidth={isFullWidth}>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          ...child.props,
          name,
          value: child.props.value,
          checked: child.props.value === value,
          onChange: () => onChange(child.props.value),
          isFullWidth
        })
      })}
    </Wrapper>
  )
}

// eslint-disable-next-line fp/no-mutation
SegmentedControl.Item = SegmentedControlItem

export {SegmentedControl}
