import React, {ReactElement, useState} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'
import {ThemeType} from 'types/Theme'

import {Divider} from '../Divider'
import {NoStyleButton} from '../helpers/NoStyleButton'
import {Icon} from '../Icon'

type AccordionSize = Extract<SizesType, 'sm' | 'lg' | 'xl'>
interface AccordionProps {
  /** The content rendered inside the open Accordion */
  children: React.ReactNode
  /** Pass through className to allow styles overrides */
  className?: string
  /** The header or title that is always on display */
  header?: ReactElement | string
  /** Whether the accordion will start collapsed or not */
  isOpenByDefault?: boolean
  /** Callback invoked whenever the collapsed state changes */
  onToggle?: (state: boolean) => void
  /** Any React component can be used as an icon */
  icon?: React.ReactNode
  /** If set, accordion trigger will be rendered under the collapsible content */
  isBottomAligned?: boolean
  /** The size of the accordion header */
  size?: AccordionSize
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether to show divider on the bottom of Accordion */
  withDivider?: boolean
  /** Change the arrow Icon to chevron */
  chevronIcon?: boolean
  /** Whether to show header side padding*/
  hasSidePadding?: boolean
}

const withSmallSize = ({
  theme,
  size
}: {
  theme: ThemeType
  size: AccordionSize
}) => {
  switch (size) {
    case 'sm':
      return css`
        padding: ${theme.layout.spacing.s300}px 0;
        align-self: flex-start;
      `
    case 'xl':
      return css`
        padding: ${theme.layout.spacing.s600}px 0;
      `
    default:
      return css`
        padding: ${theme.layout.spacing.s500}px 0;
      `
  }
}

const TabElement = styled.div<{
  isBottomAligned: boolean
  hasSidePadding: boolean
}>(
  ({theme, isBottomAligned, hasSidePadding = false}) => css`
    display: flex;
    flex-direction: ${isBottomAligned ? 'column-reverse' : 'column'};
    padding: 0 ${hasSidePadding ? theme.layout.spacing.s600 : 0}px;
  `
)

export const Header = styled(NoStyleButton)<{size: AccordionSize}>(
  ({theme}) => css`
    display: flex;
    text-align: left;
    align-items: center;
    margin: 0;
    cursor: pointer;
    gap: ${theme.layout.spacing.s300}px;
    border-radius: ${theme.layout.radius.lg}px;
    &:focus-visible {
      outline: 2px solid ${theme.colors.border.interactive.c950};
    }
  `,
  withSmallSize
)

export const Content = styled.div<{show: boolean}>(
  ({theme, show}) => css`
    display: ${show ? 'block' : 'none'};
    ${theme.typography.text.bodyM}
  `
)

export const HeaderText = styled.span<{size: AccordionSize}>(
  ({theme, size}) => {
    if (size === 'sm') {
      return css`
        ${theme.typography.text.bodyS}
      `
    }
    return css`
      ${theme.typography.text.labelM}
      flex-grow: 1;
    `
  }
)

export const ArrowElementWrapper = styled.div<{isOpen: boolean}>(
  ({theme, isOpen}) => css`
    transition: transform 0.2s ease 0s;
    transform: ${isOpen ? 'rotateZ(0)' : 'rotateZ(180deg)'};
    color: ${theme.colors?.content.interactive.c500};
  `
)

export const Accordion = ({
  header = '',
  isOpenByDefault = false,
  children,
  className,
  onToggle,
  isBottomAligned = false,
  size = 'lg',
  icon,
  dataId,
  withDivider = false,
  chevronIcon = false,
  hasSidePadding = false
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)

  const handleOnOpen = () => {
    setIsOpen(!isOpen)
    if (onToggle) onToggle(!isOpen)
  }

  return (
    <>
      <TabElement
        data-id={dataId}
        isBottomAligned={isBottomAligned}
        className={className}
        hasSidePadding={hasSidePadding}
      >
        <Header size={size} onClick={handleOnOpen} type="button">
          {icon && <div>{icon}</div>}
          <HeaderText size={size}>{header}</HeaderText>
          <ArrowElementWrapper isOpen={isOpen}>
            {chevronIcon ? (
              <Icon
                name="ChevronUp"
                colorPath="content.neutral.c600"
                size="md"
              />
            ) : (
              <Icon
                name="CaratUp"
                size="sm"
                colorPath="content.interactive.c500"
              />
            )}
          </ArrowElementWrapper>
        </Header>
        <Content show={isOpen}>{children}</Content>
      </TabElement>
      {withDivider && <Divider />}
    </>
  )
}
