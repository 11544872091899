import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'
import {ThemeType} from 'types/Theme'

import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {mq} from '../../../../utils/breakpoints'
import {Badge} from '../../Badge'
import {Icon} from '../../Icon'
import {Tag} from '../../Tag'

export type InputBaseSize = Extract<SizesType, 'sm' | 'md' | 'lg'>

export enum TextTransform {
  uppercase = 'uppercase',
  capitalize = 'capitalize',
  lowercase = 'lowercase',
  none = 'none'
}

export enum InputTextType {
  text = 'text',
  password = 'password',
  email = 'email',
  tel = 'tel',
  search = 'search',
  textarea = 'textarea'
}

export const CLEAR_BUTTON_MIN_WIDTH = 14

interface StyleProps {
  showInvalid?: boolean
  showValid?: boolean
  isResizable?: boolean
  isRounded?: boolean
  sizeVariant?: InputBaseSize
  textTransformType?: TextTransform
  withBorder?: boolean
  withStartIcon?: boolean
  withEndIcon?: boolean
}

interface CaptionWrapperProps {
  disabled?: boolean
  showInvalid?: boolean
}

interface ValidationBadgeProps {
  isVerified?: boolean
  showInvalid?: boolean
  showValid?: boolean
}

interface IconsWrapperProps {
  isRounded?: boolean
  sizeVariant?: InputBaseSize
}

interface WithInsideLabelIconsWrapperProps {
  sizeVariant: InputBaseSize
  withRightShifting?: boolean
}

interface CaratWrapperProps {
  isOpen: boolean
  disabled?: boolean
  sizeVariant: InputBaseSize
}

interface IconSizeMapItem {
  padding: number
  rightIconWidth: number
  height: number
}

export const SM_INPUT_HEIGHT = 30
export const MD_INPUT_HEIGHT = 42
export const LG_INPUT_HEIGHT = 48

export const MD_INPUT_WITH_INSIDE_LABEL_HEIGHT = 48
export const LG_INPUT_WITH_INSIDE_LABEL_HEIGHT = 56

export const iconsSizeMap: Record<InputBaseSize, IconSizeMapItem> = {
  sm: {
    padding: 8,
    rightIconWidth: 30,
    height: SM_INPUT_HEIGHT
  },
  md: {
    padding: 12,
    rightIconWidth: 40,
    height: MD_INPUT_HEIGHT
  },
  lg: {
    padding: 12,
    rightIconWidth: 44,
    height: LG_INPUT_HEIGHT
  }
}

export const iconsWithInsideLabelSizeMap: Record<
  InputBaseSize,
  IconSizeMapItem
> = {
  sm: {
    padding: 12,
    rightIconWidth: 40,
    height: MD_INPUT_WITH_INSIDE_LABEL_HEIGHT
  },
  md: {
    padding: 12,
    rightIconWidth: 40,
    height: MD_INPUT_WITH_INSIDE_LABEL_HEIGHT
  },
  lg: {
    padding: 12,
    rightIconWidth: 44,
    height: LG_INPUT_WITH_INSIDE_LABEL_HEIGHT
  }
}

const FS_PRIVACY_MASK_CLASS = 'fs-mask'
export const ICON_SIZE = 20
const VALIDATION_ICON_SIZE = 16

export const withIconPadding = ({
  theme,
  sizeVariant = 'lg',
  isRounded = false,
  withStartIcon = false,
  withEndIcon = true,
  showInvalid = false,
  showValid = false
}: StyleProps & {theme: ThemeType}) => {
  const gap = theme.layout.spacing.s250
  const roundedPadding = isRounded ? theme.layout.spacing.s200 : 0
  const size = iconsSizeMap[sizeVariant]
  const withValidationIcon = showInvalid || showValid

  const paddingLeft = size.padding + Number(withStartIcon && ICON_SIZE + gap)
  const paddingRight =
    roundedPadding +
    Number(withEndIcon && size.rightIconWidth) +
    Number(withValidationIcon && VALIDATION_ICON_SIZE + gap)

  return css`
    padding-left: ${paddingLeft}px;
    padding-right: ${paddingRight > 0 ? paddingRight : size.padding}px;
  `
}

export const withInsideLabelIconPadding = ({
  theme,
  sizeVariant = 'md',
  withStartIcon = false,
  withEndIcon = true,
  showInvalid = false,
  showValid = false
}: StyleProps & {theme: ThemeType}) => {
  const gap = theme.layout.spacing.s250
  const size = iconsWithInsideLabelSizeMap[sizeVariant]
  const withValidationIcon = showInvalid || showValid

  const paddingLeft = size.padding + Number(withStartIcon && ICON_SIZE + gap)
  const paddingRight =
    Number(withEndIcon && size.rightIconWidth) +
    Number(withValidationIcon && VALIDATION_ICON_SIZE + gap)

  return css`
    padding-left: ${paddingLeft}px;
    padding-right: ${paddingRight > 0 ? paddingRight : size.padding}px;
  `
}

const withIconsWrapper = ({sizeVariant = 'lg'}: IconsWrapperProps) => css`
  pointer-events: none;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  height: ${iconsSizeMap[sizeVariant].height}px;
`

const withInsideLabelIconsWrapper = ({
  sizeVariant
}: WithInsideLabelIconsWrapperProps) => css`
  pointer-events: none;
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  height: ${iconsWithInsideLabelSizeMap[sizeVariant].height}px;
`

export const StartIconsWrapper = styled.div<
  IconsWrapperProps & {sizeVariant: InputBaseSize}
>(
  withIconsWrapper,
  ({sizeVariant}) => css`
    top: 0;
    left: 0;
    padding-left: ${iconsSizeMap[sizeVariant].padding}px;
  `
)

export const WithInsideLabelStartIconsWrapper =
  styled.div<WithInsideLabelIconsWrapperProps>(
    withInsideLabelIconsWrapper,
    ({sizeVariant}) => css`
      top: 0;
      left: 0;
      padding-left: ${iconsWithInsideLabelSizeMap[sizeVariant].padding}px;
    `
  )

export const EndIconsWrapper = styled.div<IconsWrapperProps>(
  withIconsWrapper,
  ({theme, isRounded}) => css`
    top: 0;
    right: 0;
    ${!!isRounded && `padding-right: ${theme.layout.spacing.s200}px;`}
  `
)

export const WithInsideLabelEndIconsWrapper =
  styled.div<WithInsideLabelIconsWrapperProps>(
    withInsideLabelIconsWrapper,
    ({theme, withRightShifting}) => css`
      top: 0;
      right: ${withRightShifting ? theme.layout.spacing.s500 : 0}px;
      gap: ${theme.layout.spacing.s200}px;
    `
  )

export const focusShadowStyles = (theme: ThemeType) => css`
  box-shadow: 0px 0px 0px 1px ${theme.colors.input.default.focus.border};
`

export const withShadows = ({theme}: {theme: ThemeType}) => {
  return css`
    &:focus {
      ${focusShadowStyles(theme)};
    }
  `
}

export const withRadius = ({
  theme,
  isRounded,
  sizeVariant = 'lg'
}: StyleProps & {theme: ThemeType}) =>
  isRounded
    ? css`
        border-radius: ${theme.layout.radius.rounded}px;
      `
    : css`
        border-radius: ${sizeVariant === 'sm'
          ? theme.layout.radius.sm
          : theme.layout.radius.lg}px;
      `

export const withTextTransform = ({textTransformType}: StyleProps) => css`
  ${!!textTransformType && `text-transform: ${textTransformType}`};

  &::placeholder {
    text-transform: none;
  }
`

export const baseLayout = ({theme}: {theme: ThemeType}) => css`
  display: inline-flex;
  align-items: center;
  position: relative;
  outline: none;
  width: 100%;
  gap: ${theme.layout.spacing.s250}px;
  border: 1px solid ${theme.colors.input.default.border};
  color: ${theme.colors.input.default.text};
  background: ${theme.colors.input.default.background};
  -webkit-appearance: none;

  & select,
  input {
    ${theme.typography.text.bodyS};
  }

  ::-webkit-search-cancel-button {
    display: none;
  }

  &::placeholder {
    color: ${theme.colors.input.default.placeholder};
  }
`

export const defaultBorderStyles = (theme: ThemeType) => css`
  border-color: ${theme.colors.input.default.border};
`

export const activeBorderStyles = (theme: ThemeType) => css`
  border-color: ${theme.colors.input.default.active.border};
`

export const focusBorderStyles = (theme: ThemeType) => css`
  border-color: ${theme.colors.input.default.focus.border};
`

export const hoverBorderStyles = (theme: ThemeType) => css`
  border-color: ${theme.colors.input.default.hover.border};
`

export const invalidBorderStyles = (theme: ThemeType) => css`
  border-color: ${theme.colors.input.error.border};
  z-index: 1;
`

export const withBorder = ({theme}: {theme: ThemeType}) => css`
  ${defaultBorderStyles(theme)};

  ${mq.desktopXs(css`
    &:hover {
      ${hoverBorderStyles(theme)};
    }
  `)}

  &:active {
    ${activeBorderStyles(theme)};
  }

  &:focus {
    ${focusBorderStyles(theme)};
  }
`

export const withBackground = ({theme}: {theme: ThemeType}) => css`
  ${mq.desktopXs(css`
    &:hover {
      background: ${theme.colors.input.default.hover.background};
    }
  `)}

  &:active {
    background: ${theme.colors.input.default.active.background};
  }
`

export const withTypography = ({
  theme,
  sizeVariant
}: StyleProps & {theme: ThemeType}) => {
  switch (sizeVariant) {
    case 'sm':
      return css`
        ${theme.typography.text.bodyXS};
      `
    case 'md':
      return css`
        ${theme.typography.text.bodyM};

        ${mq.desktopXs(css`
          ${theme.typography.text.bodyS};
        `)}
      `
    case 'lg':
      return css`
        ${theme.typography.text.bodyM};

        ${mq.desktopXs(css`
          ${theme.typography.text.bodyS};
        `)}
      `
    default:
      return null
  }
}

export const withSize = ({
  theme,
  sizeVariant
}: StyleProps & {theme: ThemeType}) => {
  switch (sizeVariant) {
    case 'sm':
      return css`
        min-height: ${SM_INPUT_HEIGHT}px;
        padding: 0 ${theme.layout.spacing.s300}px;
      `
    case 'md':
      return css`
        min-height: ${MD_INPUT_HEIGHT}px;
        padding: 0 ${theme.layout.spacing.s400}px;
      `
    case 'lg':
      return css`
        min-height: ${LG_INPUT_HEIGHT}px;
        padding: 0 ${theme.layout.spacing.s400}px;
      `
    default:
      return null
  }
}

export const withDisabled = ({theme}: {theme: ThemeType}) => css`
  &:disabled {
    color: ${theme.colors.input.disabled.text};
    border-color: ${theme.colors.input.disabled.border};
    background-color: ${theme.colors.input.disabled.background};
  }
`

export const InputWrapper = styled.div(
  () => css`
    width: 100%;
    position: relative;
  `
)

export const InputWithInsideLabelWrapper = styled.div(
  () => css`
    width: 100%;
  `
)

export const withInvalidState = ({
  theme,
  showInvalid
}: StyleProps & {theme: ThemeType}) =>
  !!showInvalid &&
  css`
    ${invalidBorderStyles(theme)};
    ${mq.desktopXs(css`
      &:hover {
        ${invalidBorderStyles(theme)};
      }
    `)}

    &:active {
      ${invalidBorderStyles(theme)};
    }

    &:focus {
      ${focusBorderStyles(theme)};
    }
  `

export const CaptionWrapper = styled.div<CaptionWrapperProps>(
  ({theme, showInvalid}) => {
    const color = showInvalid
      ? theme.colors.input.error.caption
      : theme.colors.input.default.caption

    return css`
      padding: ${theme.layout.spacing.s200}px 0;
      color: ${color};
    `
  }
)

const VerifiedTag = () => (
  <Tag
    variant="success"
    size="sm"
    iconStart={<Icon name="Checkmark" size="xs" />}
  >
    <FormattedMessageWrapper
      id="InputText.verified"
      defaultMessage="Verified"
    />
  </Tag>
)

export const ValidationBadge = ({
  isVerified = false,
  showInvalid,
  showValid
}: ValidationBadgeProps) => {
  if (!showInvalid && !showValid) return null

  const iconsName = showValid ? 'Checkmark' : 'WarningMark'
  const variant = showValid ? 'success' : 'danger'

  return isVerified ? (
    <VerifiedTag />
  ) : (
    <Badge iconName={iconsName} size="xs" variant={variant} />
  )
}

export const getClassName = (isPrivate?: boolean) =>
  isPrivate ? FS_PRIVACY_MASK_CLASS : undefined

export const ClearButtonWrapper = styled.div(
  () => css`
    pointer-events: auto;
    min-width: ${CLEAR_BUTTON_MIN_WIDTH}px;
  `
)

export const withInsideLabelSize = ({
  theme,
  sizeVariant
}: StyleProps & {theme: ThemeType}) => {
  switch (sizeVariant) {
    case 'md':
      return css`
        height: ${MD_INPUT_WITH_INSIDE_LABEL_HEIGHT}px;
        min-height: ${MD_INPUT_WITH_INSIDE_LABEL_HEIGHT}px;
        padding: 0 ${theme.layout.spacing.s400}px;
      `
    case 'lg':
      return css`
        height: ${LG_INPUT_WITH_INSIDE_LABEL_HEIGHT}px;
        min-height: ${LG_INPUT_WITH_INSIDE_LABEL_HEIGHT}px;
        padding: 0 ${theme.layout.spacing.s400}px;
      `
    default:
      return null
  }
}

export const withInsideLabelTypography = ({theme}: {theme: ThemeType}) => css`
  ${theme.typography.text.bodyM};
`

export const CaratWrapper = styled.div<CaratWrapperProps>(
  ({theme, isOpen, disabled, sizeVariant}) => css`
    transition: transform 0.2s ease 0s;
    display: flex;
    justify-content: center;
    width: ${iconsSizeMap[sizeVariant].rightIconWidth}px;

    transform: ${isOpen ? 'rotateZ(0)' : 'rotateZ(180deg)'};
    color: ${disabled
      ? theme.colors.input.disabled.carat
      : theme.colors.input.default.carat};
  `
)

export const appearanceNone = css`
  appearance: none;
`
