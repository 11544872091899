/**
 * [OffersV3]
 *
 * This file contains the old mapping logic between the new Offers Model V3 and
 * the old daedalus backend augmentation format.
 *
 * Added as part of [this epic](https://app.shortcut.com/findhotel/epic/62938/offers-v3-model-search-clients-migration)
 * as a temporary solution while we migrate all usages to the new models.
 * Should be deleted after the epic is completed.
 */

import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import {mapObjIndexed, pipe} from 'ramda'

import {calculateTotalRate} from '@daedalus/core/src/offer/business/calculateTotalRate'
import {OfferPromoKey} from '@daedalus/core/src/offer/types/offer'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {sortOffersByPrice} from '@daedalus/core/src/offer/utils/sortOffers'
import {numberOfRooms} from '@daedalus/core/src/room/business/roomConfiguration'
import {
  AvailabilityEntity as SapiAvailabilityEntity,
  AvailabilityResults as SapiAvailabilityResults,
  HotelOfferEntity as SapiHotelOfferEntity,
  Offer as SapiOffer,
  RateBreakdown as SapiRateBreakdown,
  SplitBookingBundle as SapiSplitBookingBundle
} from '@findhotel/sapi'

import {increasePositionIndexByOne} from '../../analytics/utils/trackEventHelpers'
import {
  AvailabilityEntity,
  AvailabilityOfferEntity
} from '../../availability/types'
import {dateFormat, UTS_DATE_FORMAT} from '../../utils/date'
import {hasAnchorPrice} from './price'

declare const isUtcDate: unique symbol
type UtcDate = Date & {
  [isUtcDate]: true
}

interface RateBreakdown {
  taxes: number
  baseRate: number
  localTaxes: number
}

export interface PriceRateBreakdown extends RateBreakdown {
  calculatedTotalRate: number
  totalRate: number
  nightlyRate: number
}

interface TopOfferData {
  anchorPrice?: number
  anchorPriceNightly?: number
  offerIndexes: number[]
}

export type OldHotelOfferEntity = Pick<
  SapiHotelOfferEntity,
  'availableOffersCount' | 'id' | 'discount' | 'splitBooking'
> & {
  anchorPriceRateBreakdown?: PriceRateBreakdown
  cheapestPriceRateBreakdown?: PriceRateBreakdown
  fetchedAllOffers: boolean
  hasMoreOffers: boolean
  topOfferData?: TopOfferData
  offers: SearchOffer[]
  roomsConfig?: string
  promos?: Record<string, SearchOffer>
}

export interface MappingContext {
  checkIn: string
  checkOut: string
  numberOfRooms: number
  includeTaxes?: boolean
  includeLocalTaxes?: boolean
  includeRoomsInNightlyPrice?: boolean
}

function dateToMiddayUTC(date: string): UtcDate {
  return parseISO(`${date} 12:00:00`) as UtcDate
}

export function calculateNightlyRate(
  totalRate: number,
  checkIn: string,
  checkOut: string
) {
  const nights = differenceInDays(
    dateToMiddayUTC(checkOut),
    dateToMiddayUTC(checkIn)
  )

  if (nights < 1) {
    throw new Error('checkOut cannot be the same or less than checkIn')
  }
  return nights === 1 ? totalRate : totalRate / nights
}

function sapiOfferToOffer({
  offer,
  context,
  anchorPrice,
  index
}: {
  offer: SapiOffer & {roomsConfig?: string}
  context: MappingContext
  anchorPrice: number
  index?: number
}): SearchOffer {
  const calculatedTotalRate = calculateTotalRate(
    offer.rate,
    Boolean(context.includeTaxes),
    Boolean(context.includeLocalTaxes)
  )

  const calculatedTotalRateWithTax = calculateTotalRate(offer.rate, true, true)

  const {roomsConfig} = offer

  const numberOfRoomsToInclude =
    roomsConfig && context.includeRoomsInNightlyPrice
      ? numberOfRooms(roomsConfig)
      : context.numberOfRooms

  const nightlyRate = calculateNightlyRate(
    context.includeRoomsInNightlyPrice
      ? calculatedTotalRate * numberOfRoomsToInclude
      : calculatedTotalRate,
    context.checkIn,
    context.checkOut
  )

  const nightlyRateWithTax = calculateNightlyRate(
    context.includeRoomsInNightlyPrice
      ? calculatedTotalRateWithTax * numberOfRoomsToInclude
      : calculatedTotalRateWithTax,
    context.checkIn,
    context.checkOut
  )

  const tags = offer.tags ?? []
  const isTopOffer = tags.includes('top_offer')

  const osoOfferPosition = increasePositionIndexByOne(index) ?? undefined

  return {
    ...offer,
    calculatedTotalRate,
    hasAnchorPrice: isTopOffer
      ? hasAnchorPrice(calculatedTotalRate, anchorPrice)
      : false,
    nightlyRate,
    totalRate: calculatedTotalRateWithTax * numberOfRoomsToInclude,
    tags,
    nightlyRateWithTax,
    osoOfferPosition
  }
}

export const calculateNightlyRateWithRoomsLogic = (
  totalRate: number,
  numberOfRooms: number,
  checkIn: string,
  checkOut: string,
  includeRoomsInNightlyPrice: boolean
) =>
  calculateNightlyRate(
    includeRoomsInNightlyPrice ? totalRate * numberOfRooms : totalRate,
    checkIn,
    checkOut
  )

/**
 * Creates a split booking entity based on the provided offer entity and mapping context.
 * @param offerEntity - The offer entity to create the split booking from.
 * @param context - The mapping context.
 * @returns The created split booking entity, or undefined if the offer entity does not have a split booking.
 */
export const makeSplitBooking = (
  offerEntity: SapiHotelOfferEntity | SapiAvailabilityEntity,
  context: MappingContext
): SapiSplitBookingBundle | undefined => {
  const {splitBooking} = offerEntity as SapiHotelOfferEntity
  if (!splitBooking) {
    return undefined
  }

  const mappedSplitBooking = {
    ...splitBooking,
    offers:
      splitBooking.offers?.map(splitBookingOffer => {
        const contextPerOffer = {
          ...context,
          checkIn: splitBookingOffer.checkIn,
          checkOut: splitBookingOffer.checkOut
        }
        const splitBookingTotalRate = calculateTotalRate(
          splitBookingOffer.offer.rate,
          Boolean(contextPerOffer.includeTaxes),
          Boolean(contextPerOffer.includeLocalTaxes)
        )
        return {
          ...splitBookingOffer,
          offer: sapiOfferToOffer({
            offer: splitBookingOffer.offer,
            context: contextPerOffer,
            anchorPrice: splitBookingTotalRate
          })
        }
      }) ?? []
  }

  return mappedSplitBooking
}

/**
 * Calculates the price rate breakdown for an offer entity.
 * @param rateBreakdown - The price rate breakdown returned by the SAPI.
 * @param context - The mapping context.
 * @param roomsConfig - Rooms configuration.
 * @returns The price rate breakdown.
 */
export const calculatePriceRateBreakdown = (
  rateBreakdown: SapiRateBreakdown,
  context: MappingContext,
  roomsConfig?: string
): PriceRateBreakdown => {
  if (!rateBreakdown) {
    return {
      taxes: 0,
      baseRate: 0,
      localTaxes: 0,
      calculatedTotalRate: 0,
      totalRate: 0,
      nightlyRate: 0
    }
  }

  const numberOfRoomsToInclude =
    roomsConfig && context.includeRoomsInNightlyPrice
      ? numberOfRooms(roomsConfig)
      : context.numberOfRooms

  const calculatedTotalRate = calculateTotalRate(
    rateBreakdown,
    Boolean(context.includeTaxes),
    Boolean(context.includeLocalTaxes)
  )

  const totalRate =
    rateBreakdown?.base + rateBreakdown?.taxes + rateBreakdown?.hotelFees

  const nightlyRate = calculateNightlyRateWithRoomsLogic(
    calculatedTotalRate,
    numberOfRoomsToInclude,
    context.checkIn,
    context.checkOut,
    Boolean(context.includeRoomsInNightlyPrice)
  )

  return {
    taxes: rateBreakdown?.taxes,
    baseRate: rateBreakdown?.base,
    localTaxes: rateBreakdown?.hotelFees,
    calculatedTotalRate,
    totalRate,
    nightlyRate
  }
}

/**
 * Converts a SapiHotelOfferEntity or SapiAvailabilityEntity to an OldHotelOfferEntity.
 * @param offerEntity - The offer entity to convert.
 * @param context - The mapping context.
 * @returns The converted OldHotelOfferEntity, or undefined if no offer entity is provided.
 */
export function sapiHotelOfferEntityToHotelOfferEntity(
  offerEntity: SapiHotelOfferEntity | SapiAvailabilityEntity,
  context: MappingContext
): OldHotelOfferEntity | undefined {
  if (!offerEntity) return undefined

  // Mapped to the SapiHotelOfferEntity type because many properties are not present in the SapiAvailabilityEntity, but does not affect the mapping
  const {availableOffersCount, discount, id, offers, promos, roomsConfig} =
    offerEntity as SapiHotelOfferEntity

  const sortedOffers = sortOffersByPrice(offers)
  const mostExpensiveOffer = sortedOffers[sortedOffers.length - 1]

  const anchorPriceRateBreakdown = calculatePriceRateBreakdown(
    mostExpensiveOffer?.rate,
    context,
    roomsConfig
  )

  // added as part of: 2c5f12a1-limit-number-offers
  const cheapestPriceRateBreakdown = calculatePriceRateBreakdown(
    offerEntity?.cheapestRate,
    context,
    roomsConfig
  )

  const fetchedAllOffers = offers.length >= availableOffersCount
  const hasMoreOffers = !fetchedAllOffers

  const daedalusModelOffers = offers?.map((offer, index) => {
    // Determine if the offer should be replaced by the web2app promo offer
    const chosenOffer =
      promos?.[OfferPromoKey.Web2App]?.id === offer.id
        ? promos[OfferPromoKey.Web2App]
        : offer

    return sapiOfferToOffer({
      offer: {
        ...chosenOffer,
        roomsConfig
      },
      context,
      anchorPrice: anchorPriceRateBreakdown.calculatedTotalRate,
      index
    })
  })

  return {
    anchorPriceRateBreakdown,
    availableOffersCount,
    fetchedAllOffers,
    hasMoreOffers,
    id,
    offers: daedalusModelOffers,
    topOfferData: {
      anchorPrice: anchorPriceRateBreakdown.calculatedTotalRate,
      anchorPriceNightly: anchorPriceRateBreakdown.nightlyRate,
      offerIndexes: [0, 1, 2, 3]
    },
    cheapestPriceRateBreakdown,
    roomsConfig,
    discount,
    splitBooking: makeSplitBooking(offerEntity, context),
    promos: promos
      ? mapObjIndexed(
          offer =>
            sapiOfferToOffer({
              offer: {
                ...offer,
                roomsConfig
              },
              context,
              anchorPrice: anchorPriceRateBreakdown.calculatedTotalRate
            }),
          promos || {}
        )
      : undefined
  }
}

const formatAvailabilityOfferEntity = (
  offerEntity: SapiAvailabilityEntity,
  context: MappingContext,
  setCheckOutAsNextDay = false
): AvailabilityOfferEntity => ({
  offers: (
    sapiHotelOfferEntityToHotelOfferEntity(offerEntity, context)?.offers || []
  ).map(offer => ({
    ...offer,
    hasAnchorPrice: true
  })),
  cheapestPriceRateBreakdown: offerEntity.cheapestRate,
  cheapestNightlyRate: pipe(
    rateBreakdown =>
      calculateTotalRate(
        rateBreakdown,
        Boolean(context.includeTaxes),
        Boolean(context.includeLocalTaxes)
      ),
    totalRate => {
      const checkInDate = new Date(context.checkIn)
      const nextDayCheckOut = dateFormat(
        addDays(checkInDate, 1),
        UTS_DATE_FORMAT
      )
      const checkOut = setCheckOutAsNextDay ? nextDayCheckOut : context.checkOut
      return calculateNightlyRateWithRoomsLogic(
        totalRate,
        context.numberOfRooms,
        context.checkIn,
        checkOut,
        Boolean(context.includeRoomsInNightlyPrice)
      )
    }
  )(offerEntity.cheapestRate)
})

/**
 * Converts SapiAvailabilityResults to an AvailabilityEntity.
 * @param availabilityData - The SAPI availability data to convert.
 * @param context - The mapping context.
 * @returns The converted AvailabilityEntity.
 */
export function sapiAvailabilityToDaedalusModel(
  availabilityData: SapiAvailabilityResults,
  context: MappingContext,
  isColorPriceCalendarMapping = false
): AvailabilityEntity {
  const availabilityEntity = Object.fromEntries(
    Object.entries(availabilityData).map(([hotelId, dates]) => {
      return [
        hotelId,
        Object.fromEntries(
          Object.entries(dates).map(([date, offerEntity]) => {
            // Pass flag if it's color price calendar
            return [
              date,
              formatAvailabilityOfferEntity(
                offerEntity,
                context,
                isColorPriceCalendarMapping
              )
            ]
          })
        )
      ]
    })
  )

  return availabilityEntity
}
