import {MaybeDrafted} from '@reduxjs/toolkit/dist/query/core/buildThunks'
import {uniq} from 'ramda'

import {SearchResults} from '@findhotel/sapi'

import {SearchData, SearchState} from '.'

/**
 * Merge hotelIds in state with newly supplied hotelIds - ensuring they are unique
 */
export const mergeHotelIds = (
  stateIds: string[] = [],
  responseIds?: string[]
) => (responseIds ? uniq([...stateIds, ...responseIds]) : stateIds)

/**
 * Merge the current state (Immer draft) with new response data & current searchState
 */
export const mergeDataRecipe = (
  draft: MaybeDrafted<SearchData>,
  {
    status,
    hotelIds,
    anchorHotelId,
    hotelEntities,
    hotelOfferEntities,
    ...base
  }: Partial<SearchResults>,
  searchState?: SearchState
) => ({
  currentSearch: draft.currentSearch,
  ...base,
  status: status ?? draft.status,
  searchState: searchState ?? draft.searchState,
  hotelIds: mergeHotelIds(draft.hotelIds, hotelIds),
  anchorHotelId: anchorHotelId ?? draft.anchorHotelId,
  hotelEntities: {...draft.hotelEntities, ...hotelEntities},
  hotelOfferEntities: {
    ...draft.hotelOfferEntities,
    ...hotelOfferEntities
  }
})

/**
 * Helper to narrow the SearchData type to one with results - ideally SearchData would be a union that narrows itself
 */
export const hasSearchResults = (
  data: SearchData
): data is Required<SearchData> =>
  !!(data.hotelIds && data.hotelEntities && data.hotelOfferEntities)
