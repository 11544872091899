import {createSelector} from '@reduxjs/toolkit'

import {brands} from '../config'
import {BrandCode} from '../types'
import {
  checkIsBrandDealbase,
  checkIsBrandInternal,
  checkIsBrandKiwi,
  checkIsBrandOffersLockedByDefault,
  checkIsBrandVio,
  checkIsCookieProEnabledForBrand
} from '../utils'

export interface State {
  meta: {
    brandCode: BrandCode
  }
}

const getMeta = (state: State) => state.meta

const getBrandCodeFromMeta = createSelector([getMeta], meta => meta.brandCode)

export const getBrand = createSelector(
  [getBrandCodeFromMeta],
  brandCode => brands[brandCode]
)

export const getBrandCode = createSelector([getBrand], brand => brand.code)

export const getBrandName = createSelector([getBrand], brand => brand.name)
export const getBrandTheme = createSelector([getBrand], brand => brand.theme)
export const getBrandAddress = createSelector(
  [getBrand],
  brand => brand.address
)
export const getBrandProviderCode = createSelector(
  [getBrand],
  brand => brand.providerCode
)
export const getBrandPartnerId = createSelector(
  [getBrand],
  brand => brand.partnerId
)
export const getBrandSapiCode = createSelector(
  [getBrand],
  brand => brand.sapiCode || brand.code
)

export const getIsBrandVio = createSelector([getBrand], brand =>
  checkIsBrandVio(brand)
)

export const getIsBrandPartner = createSelector(
  [getBrand],
  brand => !checkIsBrandInternal(brand)
)

export const getIsBrandKiwi = createSelector([getBrand], brand =>
  checkIsBrandKiwi(brand)
)

export const getIsBrandDealbase = createSelector([getBrand], brand =>
  checkIsBrandDealbase(brand)
)

export const getIsBrandBasicLevel = createSelector(
  [getBrand],
  brand => brand.level === 'basic'
)

export const getIsBrandAdvancedLevel = createSelector(
  [getBrand],
  brand => brand.level === 'advanced'
)

export const getIsBrandCustomLevel = createSelector(
  [getBrand],
  brand => brand.level === 'custom'
)

export const getIsCookieProEnabledForBrand = createSelector([getBrand], brand =>
  checkIsCookieProEnabledForBrand(brand)
)

export const getIsBrandOffersLockedByDefault = createSelector(
  [getBrand],
  brand => checkIsBrandOffersLockedByDefault(brand)
)

export const getBrandShowSignIn = createSelector(
  [getBrand],
  brand => brand.showSignIn
)

export const getBrandWidgetHeaderMessage = createSelector(
  [getBrand],
  brand => brand.widgetHeaderMessage
)

export const getBrandPrivateDealMessage = createSelector(
  [getBrand],
  brand => brand.privateDealMessage
)

export const getBrandHideInternalRedirect = createSelector(
  [getBrand],
  brand => brand.hideInternalRedirect
)
