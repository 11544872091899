import React from 'react'
import {useIntl} from 'react-intl'
import {Field} from 'formik'

import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {InputText} from '@daedalus/atlas/InputText'
import messages from '@daedalus/core/src/localization/utils/validationMessages'
import {
  getValidationMessageKey as _getValidationMessageKey,
  required
} from '@daedalus/core/src/utils/validation'

const validationRules = {
  requiredPartnerKey: [
    {
      validate: required,
      messageKey: messages.requiredPartnerKey.id
    }
  ]
}

const getValidationMessageKey = _getValidationMessageKey(validationRules)

export const PartnerKeyInput = () => {
  const intl = useIntl()

  const placeholder = intl.formatMessage({
    id: 'widgetGenerator.partnerKeyPlaceholder',
    defaultMessage: 'Enter your partner key'
  })

  return (
    <InputWrapperFormik
      Field={Field}
      name="partnerKey"
      validate={getValidationMessageKey('requiredPartnerKey')}
    >
      <InputText placeholder={placeholder} name="partnerKey" id="partnerKey" />
    </InputWrapperFormik>
  )
}
