import React, {ReactElement} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {BodyVariant} from '../Text'

interface BaseProps {
  /** Content to be labeled */
  children?: ReactElement
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Content of the label */
  value: ReactElement | string
  /** Should align label to the left */
  isHorizontalAligned?: boolean
  /** The body variant of the component */
  variant?: BodyVariant
  /** Should render isRequired icon * */
  isRequired?: boolean
}

interface PropsWithChildren extends BaseProps {
  /** Content to be labeled */
  children: ReactElement
  /** Id of an explicitly associated element. Can be used to disassociate the label from its child by setting it to an empty string. */
  forId?: string
}

interface PropsWithoutChildren extends BaseProps {
  /** Content to be labeled */
  children?: ReactElement
  /** Id of an explicitly associated element. You have to provide this id if you are not wrapping element that you want to label. */
  forId: string
}

type Props = PropsWithChildren | PropsWithoutChildren

interface LabelElementProps {
  for?: string
  isHorizontalAligned?: boolean
  variant: BodyVariant
  isRequired?: boolean
}

export const RequiredMark = styled('span')(
  ({theme}) => css`
    color: ${theme.colors.content.danger.c700};
  `
)

const LabelWrapper = styled('label')<LabelElementProps>(
  ({theme, isHorizontalAligned, variant}) => css`
    ${theme.typography.text[variant]}
    display: flex;
    align-items: ${isHorizontalAligned ? 'center' : 'flex-start'};
    flex-direction: ${isHorizontalAligned ? 'row' : 'column'};
    gap: ${isHorizontalAligned
      ? `${theme.layout.spacing.s400}px`
      : `${theme.layout.spacing.s250}px`};
  `
)

export const Label = ({
  children,
  forId,
  value,
  isHorizontalAligned = false,
  variant = 'bodyM',
  isRequired = false,
  className
}: Props) => (
  <LabelWrapper
    htmlFor={forId}
    isHorizontalAligned={isHorizontalAligned}
    variant={variant}
    isRequired={isRequired}
    className={className}
  >
    <span>
      {value}
      {isRequired && <RequiredMark>*</RequiredMark>}
    </span>
    {children}
  </LabelWrapper>
)
