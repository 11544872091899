import React, {ChangeEvent, ElementType, forwardRef, ReactNode} from 'react'
import InputMask from 'react-input-mask'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '../Button'
import {
  baseLayout,
  CaptionWrapper,
  ClearButtonWrapper,
  EndIconsWrapper,
  getClassName,
  InputBaseSize,
  InputTextType,
  InputWrapper,
  StartIconsWrapper,
  TextTransform,
  ValidationBadge,
  withBackground,
  withBorder,
  withDisabled,
  withIconPadding,
  withInvalidState,
  withRadius,
  withShadows,
  withSize,
  withTextTransform,
  withTypography
} from '../helpers/InputBase'
import {Icon} from '../Icon'
import {Text} from '../Text'

interface Props {
  /** Field autocomplete id */
  autoComplete?: string
  /** Pass through classname to allow styles overrides */
  className?: string
  /** An object of custom props that is not explicitly supported, but might be required for some custom implementations.
   * This can also be used to override the explicit props, like a custom onChange function.
   * or as a way to get rid of the chrome autofill.
   * */
  customProps?: Record<string, any>
  /** HTML Tag to wrap the caption Text component */
  captionTag?: ElementType<any>
  /** Message to display below input. Changes color along with validation highlights. */
  caption?: ReactNode
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Identify the close button for selection in integration tests, FullStory, etc. */
  closeButtonDataId?: string
  /** Whether the input is disabled */
  disabled?: boolean
  /** Whether the input has an error */
  hasError?: boolean
  /** An Icon to display on the left side of the input */
  icon?: JSX.Element
  /** ID of this component, passed down by the controlling parent component */
  id: string
  /** Whether the input content is private and should be masked in FullStory */
  isPrivate?: boolean
  /** Whether the input is rounded */
  isRounded?: boolean
  /** Whether the user has interacted with this input */
  isTouched?: boolean
  /** Display verified label instead of valid icon */
  isVerified?: boolean
  /** Input list property https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#list */
  list?: string
  /** The mask used to format the user input */
  mask?: string
  /** Name of this component, passed down by the controlling parent component */
  name: string
  /** An onFocus callback passed down by the controlling parent component */
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  /** An onBlur callback passed down by the controlling parent component */
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  /** An onChange callback passed down by the controlling parent component */
  onChange?: (value: string, event: ChangeEvent) => void
  /** An onClear callback passed down by the controlling parent component. The clear button will only be visible if this callback is present. */
  onClear?: (event: React.SyntheticEvent) => void
  /** Content to be used as the input placeholder. It must be translated string */
  placeholder: string
  /** Formik function for setting a specific field's value. Used to update the value without sending an HTML event. */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  /** The size of the input */
  size?: InputBaseSize
  /** CSS text-transform style to apply to the input field */
  textTransformType?: TextTransform
  /** Specify the HTML input type */
  type?: InputTextType
  /** The value passed down by the controlling parent component */
  value?: string | number
}

export const MaskedInput = styled(InputMask)(
  baseLayout,
  withSize,
  withBorder,
  withBackground,
  withInvalidState,
  withShadows,
  withIconPadding,
  withRadius,
  withDisabled,
  withTextTransform,
  withTypography,
  () => css`
    z-index: 0;
  `
)

export const TextInput = styled.input(
  baseLayout,
  withSize,
  withBorder,
  withBackground,
  withInvalidState,
  withShadows,
  withIconPadding,
  withRadius,
  withDisabled,
  withTextTransform,
  withTypography,
  () => css`
    z-index: 0;
  `
)

export const InputText = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoComplete,
      className,
      customProps,
      caption,
      captionTag,
      dataId,
      closeButtonDataId,
      disabled,
      hasError,
      icon,
      id,
      isPrivate,
      isRounded,
      isTouched,
      isVerified,
      list,
      mask,
      name,
      onBlur,
      onFocus,
      onChange,
      onClear,
      placeholder,
      setFieldValue,
      size = 'lg',
      textTransformType = TextTransform.none,
      type = InputTextType.text,
      value
    },
    ref
  ) => {
    const showInvalid = isTouched && hasError
    const showValid = isTouched && !hasError

    const privateClassName = getClassName(isPrivate)

    const handleChange = (event: ChangeEvent) => {
      const newValue = (event.currentTarget as HTMLInputElement).value

      if (setFieldValue) {
        setFieldValue(name, newValue)
      } else if (onChange) {
        onChange(newValue, event)
      }
    }

    const commonProps = {
      autoComplete,
      id,
      disabled,
      isRounded,
      showInvalid,
      showValid,
      maskChar: null,
      name,
      list,
      sizeVariant: size,
      textTransformType,
      type,
      value,
      placeholder,
      onBlur,
      onFocus,
      onChange: handleChange,
      className: privateClassName,
      withStartIcon: Boolean(icon),
      withEndIcon: Boolean(onClear),
      ['data-id']: dataId,
      ['data-field-error']: hasError
    }

    return (
      <InputWrapper className={className}>
        {mask ? (
          <MaskedInput
            inputRef={ref}
            mask={mask}
            {...commonProps}
            {...customProps}
          />
        ) : (
          <TextInput ref={ref} {...commonProps} {...customProps} />
        )}

        <StartIconsWrapper sizeVariant={size}>{icon}</StartIconsWrapper>
        <EndIconsWrapper isRounded={isRounded} sizeVariant={size}>
          <ValidationBadge
            isVerified={isVerified}
            showInvalid={showInvalid}
            showValid={showValid}
          />

          <ClearButtonWrapper>
            {onClear && value && (
              <Button
                isRounded
                hasTouchArea={size === 'lg'}
                variant="quiet"
                size="sm"
                dataId={closeButtonDataId}
                iconStart={
                  <Icon
                    name="Clear"
                    size="md"
                    colorPath="content.neutral.c600"
                  />
                }
                onClick={onClear}
              />
            )}
          </ClearButtonWrapper>
        </EndIconsWrapper>

        {caption && (
          <CaptionWrapper disabled={disabled} showInvalid={showInvalid}>
            <Text variant="labelXS" as={captionTag}>
              {caption}
            </Text>
          </CaptionWrapper>
        )}
      </InputWrapper>
    )
  }
)

// eslint-disable-next-line fp/no-mutation
InputText.displayName = 'InputText'
