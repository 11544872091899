import React from 'react'
import {useIntl} from 'react-intl'
import styled from '@emotion/styled'
import {Field, useFormikContext} from 'formik'
import {useSearchBoxDestinationPicker} from 'hooks/searchBox'
import {WidgetLabelTypes} from 'utils/label'
import {DestinationPickerPopover} from 'widgets/components/DestinationPicker/DestinationPickerPopover'
import {WidgetGeneratorFormValues} from 'widgetsGenerator/GeneratorForm'

import {Button} from '@daedalus/atlas/Button'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {InputWrapperFormik} from '@daedalus/atlas/helpers/InputWrapperFormik'
import {Icon} from '@daedalus/atlas/Icon'
import {InputSelect} from '@daedalus/atlas/InputSelect'
import {Tag} from '@daedalus/atlas/Tag'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import messages from '@daedalus/core/src/localization/utils/validationMessages'
import {getValidationMessageKey as _getValidationMessageKey} from '@daedalus/core/src/utils/validation'
const Wrapper = styled(Grid)`
  width: 434px;
`

const StyledSelect = styled(InputSelect)`
  max-width: 158px;
  min-width: 158px;
`

const StyledButton = styled(Button)`
  min-width: 49px;
  max-width: 49px;
`

const IconWrapper = styled('div')`
  cursor: pointer;
`

const validationRules = (isSearchNames: boolean) => ({
  widgetGeneratorSearch: [
    {
      validate: () => isSearchNames,
      messageKey: messages.widgetGeneratorSearch.id
    }
  ]
})

const getValidationMessageKey = (isSearchNames: boolean) =>
  _getValidationMessageKey(validationRules(isSearchNames))

interface Props {
  searchNames: {id: number; name: string}[]
  setSearchNames: (newArr: {id: number; name: string}[]) => void
}

export const SearchInput = ({searchNames, setSearchNames}: Props) => {
  const {formatMessage} = useIntl()
  const {
    values: {destination, searchType, widgetType},
    setFieldValue,
    setTouched
  } = useFormikContext<WidgetGeneratorFormValues>()

  const {onSuggestionsRequested, searchValue, suggestions} =
    useSearchBoxDestinationPicker({})

  const onAddButtonClick = () => {
    setFieldValue('destination', '')
    const newId = searchNames.length

    setSearchNames([...searchNames, {id: newId, name: destination}])
    setTouched({}, true)
  }

  const onCloseIconClick = (id: number) => {
    setSearchNames(searchNames.filter(item => item.id !== id))
  }

  return (
    <>
      <Wrapper
        container
        direction="row"
        spacing="s000"
        gap="s300"
        alignItems="baseline"
        wrap="nowrap"
      >
        {widgetType === WidgetLabelTypes.multipleHotels && (
          <InputWrapperFormik Field={Field} name="searchType">
            <StyledSelect
              size="md"
              value={searchType}
              placeholder={formatMessage({
                id: 'widgetGenerator.searchType',
                defaultMessage: 'Search type'
              })}
            >
              <option key="hotelNames" value="hotelNames">
                {formatMessage({
                  id: 'widgetGenerator.hotelNames',
                  defaultMessage: 'Hotel names'
                })}
              </option>
              <option key="placeName" value="placeName">
                {formatMessage({
                  id: 'widgetGenerator.placeName',
                  defaultMessage: 'Place name'
                })}
              </option>
            </StyledSelect>
          </InputWrapperFormik>
        )}

        <DestinationPickerPopover
          value={destination}
          onChange={newDestination => {
            setFieldValue('destination', newDestination?.displayValue)
          }}
          withFormik
          validate={getValidationMessageKey(Boolean(searchNames.length))(
            'widgetGeneratorSearch'
          )}
          isRtl={false}
          searchValue={searchValue}
          suggestions={suggestions}
          onSuggestionsRequested={onSuggestionsRequested}
        />

        <StyledButton
          type="button"
          onClick={onAddButtonClick}
          size="lg"
          disabled={
            ((searchType === 'placeName' ||
              widgetType === WidgetLabelTypes.hotelCard) &&
              searchNames.length > 0) ||
            !destination
          }
        >
          <FormattedMessageWrapper
            id="widgetGenerator.addButton"
            defaultMessage="Add"
          />
        </StyledButton>
      </Wrapper>
      <Grid container spacing="s000" gap="s300">
        {searchNames.map(({name, id}) => (
          <Tag
            key={id}
            iconEnd={
              <IconWrapper onClick={() => onCloseIconClick(id)}>
                <Icon name="Close" />
              </IconWrapper>
            }
          >
            {name}
          </Tag>
        ))}
      </Grid>
    </>
  )
}
