import {defineMessages} from 'react-intl'

const validationMessages = defineMessages({
  requiredField: {
    id: 'requiredField',
    defaultMessage: 'This is a required Field'
  },
  emptyFirstName: {
    id: 'emptyFirstName',
    defaultMessage: 'Please enter your first name'
  },
  emptyFirstNameV2: {
    id: 'emptyFirstNameV2',
    defaultMessage: 'Enter your first name'
  },
  emptyLastName: {
    id: 'emptyLastName',
    defaultMessage: 'Please enter your last name'
  },
  emptyLastNameV2: {
    id: 'emptyLastNameV2',
    defaultMessage: 'Enter your last name'
  },
  tooLongName: {
    id: 'tooLongName',
    defaultMessage: "Names can't be longer then 50 characters"
  },
  invalidSpecialCharacters: {
    id: 'invalidSpecialCharacters',
    defaultMessage: "Names can't contain numbers or symbols (except - ~ ,)"
  },
  invalidNonLatinCharacters: {
    id: 'invalidNonLatinCharacters',
    defaultMessage: 'Please use Latin characters only (A-Z, a-z, 0-9)'
  },
  invalidNonLatinCharactersV2: {
    id: 'invalidNonLatinCharactersV2',
    defaultMessage: 'Use Latin characters only (a-z)'
  },
  invalidNonLatinCharactersBillingAddress: {
    id: 'invalidNonLatinCharactersBillingAddress',
    defaultMessage: 'Use Latin characters only (a-z, 0-9)'
  },
  invalidPhoneNumber: {
    id: 'invalidPhoneNumber',
    defaultMessage: 'Insert a valid phone number'
  },
  invalidPhoneNumberV2: {
    id: 'invalidPhoneNumberV2',
    defaultMessage:
      'This doesn’t look like a valid phone number (only numbers, dashes, and spaces allowed)'
  },
  emptyPhoneNumber: {
    id: 'emptyPhoneNumber',
    defaultMessage: 'Please enter your phone number'
  },
  emptyPhoneNumberV2: {
    id: 'emptyPhoneNumberV2',
    defaultMessage: 'Enter your phone number'
  },
  invalidOneTimeCode: {
    id: 'invalidOneTimeCode',
    defaultMessage:
      'Incorrect code. Try again or go back and use a different phone number.'
  },
  invalidEmailAddress: {
    id: 'invalidEmailAddress',
    defaultMessage: 'Insert a valid email address'
  },
  invalidEmailAddressV2: {
    id: 'invalidEmailAddressV2',
    defaultMessage: 'This doesn’t look like a valid email'
  },
  invalidEmailAddressB: {
    id: 'invalidEmailAddressB',
    defaultMessage:
      "This isn't a correct email format. Please check and try again"
  },
  emptyEmailAddress: {
    id: 'emptyEmailAddress',
    defaultMessage: 'Please enter your email address'
  },
  emptyEmailAddressV2: {
    id: 'emptyEmailAddressV2',
    defaultMessage: 'Enter your email address'
  },
  emptyCity: {
    id: 'emptyCity',
    defaultMessage: 'Please enter your city'
  },
  emptyCityV2: {
    id: 'emptyCityV2',
    defaultMessage: 'Enter your city'
  },
  invalidCreditCard: {
    id: 'invalidCreditCard',
    defaultMessage: 'Insert a valid card number'
  },
  invalidCreditCardCVV: {
    id: 'invalidCreditCardCVV',
    defaultMessage: 'Insert a valid CVV'
  },
  emptyCreditCardSecurityCode: {
    id: 'emptyCreditCardSecurityCode',
    defaultMessage: 'Please enter a valid security code'
  },
  invalidCreditCardSecurityCodeAMX: {
    id: 'invalidCreditCardSecurityCodeAMX',
    defaultMessage: `Please enter a valid security code<break></break>
    • Enter the 4 numbers on the front of your card`
  },
  invalidCreditCardSecurityCodeAMXV2: {
    id: 'invalidCreditCardSecurityCodeAMXV2',
    defaultMessage: 'Use a 4-digit code, as shown on your card.'
  },
  invalidCreditCardSecurityCodeOthers: {
    id: 'invalidCreditCardSecurityCodeOthers',
    defaultMessage: `Please enter a valid security code<break></break>
    • Enter the 3 numbers on the back of your card`
  },
  invalidCreditCardSecurityCodeOthersV2: {
    id: 'invalidCreditCardSecurityCodeOthersV2',
    defaultMessage: 'Use a 3-digit code, as shown on your card.'
  },
  invalidCreditCardSecurityCodeUnknown: {
    id: 'invalidCreditCardSecurityCodeUnknown',
    defaultMessage: `Please enter a valid security code<break></break>
    • Enter the 3 numbers on the back of your card<break></break>
    • Enter the 4 numbers on the front of your card (Amex)`
  },
  invalidCreditCardSecurityCodeUnknownV2: {
    id: 'invalidCreditCardSecurityCodeUnknownV2',
    defaultMessage: 'Use a 3 or 4-digit code, as shown on your card.'
  },

  invalidCardExpirationDate: {
    id: 'invalidCardExpirationDate',
    defaultMessage: 'Enter a month and a year in the future (MM/YY)'
  },
  emptyCardExpiration: {
    id: 'emptyCardExpiration',
    defaultMessage: 'Please enter a valid date'
  },
  emptyCardExpirationV2: {
    id: 'emptyCardExpirationV2',
    defaultMessage: 'Enter expiry date (mm/yy)'
  },
  invalidPassword: {
    id: 'invalidPassword',
    defaultMessage: 'Insert a valid password'
  },
  invalidCreditCardType: {
    id: 'invalidCreditCardType',
    defaultMessage: 'This card type is not accepted'
  },
  emptyCreditCard: {
    id: 'emptyCreditCard',
    defaultMessage: 'Please enter a valid card number'
  },
  emptyCreditCardV2: {
    id: 'emptyCreditCardV2',
    defaultMessage: 'Enter a valid card number'
  },
  emptyAddressLine1: {
    id: 'emptyAddressLine1',
    defaultMessage: 'Please enter your street and number'
  },
  emptyAddressV2: {
    id: 'emptyAddressV2',
    defaultMessage: 'Enter the first line of your address'
  },
  emptyPostalCode: {
    id: 'emptyPostalCode',
    defaultMessage: 'Please enter your postal code'
  },
  emptyPostalCodeV2: {
    id: 'emptyPostalCodeV2',
    defaultMessage: 'Enter your postal code'
  },
  invalidPostalCode: {
    id: 'invalidPostalCode',
    defaultMessage:
      "This doesn't look like a valid postal code. You can only use letters, numbers and dashes"
  },
  invalidCreditCardholdersname: {
    id: 'invalidCreditCardholdersname',
    defaultMessage:
      'Please enter your cardholder name as it appears on your card'
  },
  invalidCreditCardholdersName: {
    id: 'invalidCreditCardholdersName',
    defaultMessage: 'Enter the name as it appears on your card'
  },
  addressNotFound: {
    id: 'addressNotFound',
    defaultMessage: 'Sorry, we could not find this address'
  },
  wishListNameMinLength: {
    id: 'wishListNameMinLength',
    defaultMessage: 'Enter a name of at least 1 character'
  },
  wishListNameMaxLength: {
    id: 'wishListNameMaxLength',
    defaultMessage: 'Enter a name with less than 50 characters'
  },
  maximumAttemptsReached: {
    id: 'magicLinkModal.maximumAttemptsInformation',
    defaultMessage:
      'You have reached the maximum number of attempts. Please try again later.'
  },
  requiredPartnerKey: {
    id: 'requiredPartnerKey',
    defaultMessage: 'Enter your partner key'
  },
  widgetGeneratorSearch: {
    id: 'widgetGeneratorSearch',
    defaultMessage: 'Location or hotel name is needed'
  },
  emptyCompanyName: {
    id: 'emptyCompanyName',
    defaultMessage: 'Enter company name'
  },
  emptyCRN: {
    id: 'emptyCRN',
    defaultMessage: 'Enter company registration number'
  },
  emptyVAT: {
    id: 'emptyVAT',
    defaultMessage: 'Enter VAT identification number'
  },
  requestRequired: {
    id: 'requestRequired',
    defaultMessage: 'Input required. Enter a valid information'
  },
  requestInvalid: {
    id: 'requestInvalid',
    defaultMessage: 'Input is invalid. Enter a valid information'
  }
} as const)

export type ValidationKeys = keyof typeof validationMessages

export default validationMessages
