import React, {ReactElement} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {mq} from '../../../utils/breakpoints'
import {Text} from '../Text'

interface Props {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Name of radio button. Programmatically set by parent component SegmentedControl */
  name?: string
  /** Value of current radio button */
  value: string
  /** Checked state of current radio button. Programmatically set by parent component */
  checked?: boolean
  /** An onChange callback passed down by the controlling parent component */
  onChange?: (event: React.ChangeEvent) => void
  /** An icon placed before the item content. It can be any type of React Element */
  icon?: ReactElement
  /** Text content of current item */
  children: string | ReactElement
  /** Whether the SegmentedControl should span the whole available width. Programmatically set by parent component  */
  isFullWidth?: boolean
}

const ITEM_MIN_WIDTH = 62
const DIVIDER_HEIGHT = 22
const DIVIDER_WIDTH = 1
const DIVIDER_TOP = 6

const Wrapper = styled('label')<{isFullWidth: boolean}>(
  ({theme, isFullWidth}) => css`
    display: ${isFullWidth ? 'inline-grid' : 'inline-flex'};
    ${isFullWidth && 'flex-grow: 1;'}
    align-items: center;
    position: relative;
    &:first-child {
      ${ItemBody} {
        border-radius: ${theme.layout.radius.lg}px 0 0
          ${theme.layout.radius.lg}px;
        html[dir='rtl'] & {
          border-radius: 0 ${theme.layout.radius.lg}px
            ${theme.layout.radius.lg}px 0;
        }
      }
    }
    &:last-child {
      ${ItemBody} {
        border-radius: 0 ${theme.layout.radius.lg}px ${theme.layout.radius.lg}px
          0;
        html[dir='rtl'] & {
          border-radius: ${theme.layout.radius.lg}px 0 0
            ${theme.layout.radius.lg}px;
        }
        &:after {
          display: none;
        }
      }
    }
    ${mq.desktopXs(css`
      &:hover {
        ${ItemBody} {
          background-color: ${theme.colors.segmentedControl.default.hover
            .background};
          border-color: ${theme.colors.segmentedControl.default.hover
            .background};
          color: ${theme.colors.segmentedControl.default.hover.content};
        }
      }
    `)}
  `
)

export const ItemBody = styled('div')(({theme}) => {
  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.layout.spacing.s200}px;
    background: ${theme.colors.segmentedControl.default.background};
    min-width: ${ITEM_MIN_WIDTH}px;
    padding: ${theme.layout.spacing.s250}px ${theme.layout.spacing.s400}px;
    border: 1px solid ${theme.colors.segmentedControl.default.background};
    color: ${theme.colors.segmentedControl.default.content};
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      height: ${DIVIDER_HEIGHT}px;
      width: ${DIVIDER_WIDTH}px;
      top: ${DIVIDER_TOP}px;
      right: 0;
      left: auto;
      background-color: ${theme.colors.segmentedControl.default.divider};
    }
  `
})

export const Input = styled('input')(
  ({theme}) => css`
    position: absolute;
    left: -9999px;
    top: 0;
    opacity: 0;
    &:focus-visible {
      + ${ItemBody} {
        outline: 1px solid ${theme.colors.segmentedControl.default.focus.border};
        outline-offset: 1px;
        z-index: 1;
      }
    }
    &:active {
      + ${ItemBody} {
        background-color: ${theme.colors.segmentedControl.default.active
          .background};
        border-color: ${theme.colors.segmentedControl.default.active
          .background};
        color: ${theme.colors.segmentedControl.default.active.content};
        &:after {
          background-color: ${theme.colors.segmentedControl.default.active
            .background};
        }
      }
    }
    &:checked {
      + ${ItemBody} {
        background-color: ${theme.colors.segmentedControl.checked.background};
        border-color: ${theme.colors.segmentedControl.checked.background};
        color: ${theme.colors.segmentedControl.checked.content};
        &:after {
          background-color: ${theme.colors.segmentedControl.checked.background};
        }
      }
    }
  `
)

export const SegmentedControlItem = ({
  icon,
  name,
  value,
  checked,
  onChange,
  isFullWidth = false,
  children,
  className
}: Props) => {
  return (
    <Wrapper className={className} isFullWidth={isFullWidth}>
      <Input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <ItemBody>
        {icon && icon}
        <Text variant="labelS" as="span">
          {children}
        </Text>
      </ItemBody>
    </Wrapper>
  )
}
